import { Settings } from 'lucide-react';

export const APP_ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',
  PAGE_NOT_FOUND: '/page-not-found',
  OTP_VERIFICATION: '/otp-Verification',
};

export const DASHBOARD_NON_SIDEBAR_ROUTES = {
  VENDOR_LOUNGE: '/vendor-lounge',
  VENDOR_PROFILE: '/vendor-profile',
  ADD_VENDOR_MANUAL: '/add-vendor-manual',
  MEMBER_PROFILE: '/member-profile',
  PROFILE: '/profile',
  SEARCH_PANEL: '/search-panel',
};

export const DASHBOARD_SIDEBAR_ROUTES = {
  HOME: '/dashboard',
  CONCEIRGE: '/concierge',
  VENDOR_HUB: '/vendor-hub',
  COMMUNITY: '/community',
  YOUZAK: '/youzak',
  MXINSIGHTS: '/mx-insights',
  INVITE: '/invite',
  SUPPORT: '/support',
  SETTINGINCOGNITO: '/incognito',
  SETTING_ACCOUNT_SECURITY: '/account-security',
  ...DASHBOARD_NON_SIDEBAR_ROUTES,
};
